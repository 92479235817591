import { DateFormatter, DateTimeFormatter, ShortDateFormatter } from "./DateFormatter"

const useDateTimeService = () => {

  const dateTimeFormatter = (params) => {
    try
    {
      if (params && params.value)
      {
        return DateTimeFormatter.format(new Date(params.value))
      }
      else {
        return ''
      }
    }
    catch
    {
      return ''
    }
  }

  const dateFormatter = (params) => {
    try
    {
      return DateFormatter.format(new Date(params.value))
    }
    catch
    {
      return ''
    }
  }

  const shortDateFormatter = (params) => {
    try
    {
      if (params.value)
        return ShortDateFormatter.format(new Date(params.value))
      else
        return ''
    }
    catch
    {
      return ''
    }
  }

  return { dateFormatter, dateTimeFormatter, shortDateFormatter }
}

export { useDateTimeService };
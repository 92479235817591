import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { AgGridReact } from 'ag-grid-react';
import { LoadingOverlay } from '../widgets/LoadingOverlay';
//import BasicGroupingHeader from '../widget/BasicGroupingHeader';
import { useDateTimeService } from '../../services/DateTimeFormatterService';
import CurrencyFormatter from '../widgets/CurrencyFormatter';

const DashboardGrid = ({listingData, isLoading, perPage, onSelectionChanged }) => {
  const gridRef = useRef()
  const { dateFormatter, dateTimeFormatter } = useDateTimeService()

  const initialColumnDef = [
    {
      field: "createdOn",
      headerName: "Date / Time Submitted",
      tooltipField: "createdOn",
      filter: 'agDateColumnFilter',
      suppressSizeToFit: false,
      minWidth: 180,
      valueFormatter: dateTimeFormatter,
    },
    { field: 'vendor', minWidth: 130, suppressSizeToFit: false, },
    { field: 'invoice', headerName: 'Invoice #', maxWidth: 150, suppressSizeToFit: false },
    {
      field: "billDate",
      headerName: "Bill Date",
      tooltipField: "billDate",
      filter: 'agDateColumnFilter',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      suppressSizeToFit: true,
      maxWidth: 125,
      valueFormatter: dateFormatter,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      tooltipField: "dueDate",
      filter: 'agDateColumnFilter',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      suppressSizeToFit: true,
      maxWidth: 130,
      valueFormatter: dateFormatter,
    },
    {
      field: 'amount',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'text-end',
      maxWidth: 125,
      suppressSizeToFit: true,
      filter: 'agNumberColumnFilter',
      cellRendererSelector: (params) => {
        return {
          component: CurrencyFormatter
        }
      }
    },
    { field: 'property', minWidth: 200, suppressSizeToFit: false },
    { field: 'stage', maxWidth: 130, suppressSizeToFit: true }
  ]

  const [columnDefs] = useState(initialColumnDef);

  const [defaultColumnDefs] = useState({
    sortable: true,
    filter: true,
    resizable: true
  })

  const setGridSelection = () => {
    if (gridRef.current) {
      const selectedNodes = gridRef.current.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data)
      onSelectionChanged(selectedData)
      //setSelection(selectedData)
    }
    else {
      onSelectionChanged([])
      //setSelection([])
    }
  }

  const onShowLoading = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay()
    }
  }, [])

  const onHideLoading = useCallback(() => {
    if (gridRef && gridRef.current && gridRef.current.api) {
      gridRef.current.api.hideOverlay()

      if(gridRef.current.api.getDisplayedRowCount() === 0) {
        gridRef.current.api.showNoRowsOverlay()
      }
    }
  }, []);

  useEffect(() => {
    console.log('use effect triggered on isLoading changed to ' + isLoading)
    if (isLoading) {
      onShowLoading()
    }
    else {
      onHideLoading()
    }
  }, [isLoading, onShowLoading, onHideLoading])

  return (
    <Fragment>
      <div className="ag-theme-alpine compact" style={{ height: '70vh', minHeight: '400px', width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          //getRowNodeId={getRowNodeId}
          //onCellValueChanged={onCellValueChanged}
          //onCellEditingStopped={onCellEditingStopped}
          //stopEditingWhenCellsLoseFocus={true}
          suppressStickyLabel={true}
          rowData={listingData}
          rowSelection="single"
          paginationPageSize={perPage.value}
          columnDefs={columnDefs}
          loadingOverlayComponent={LoadingOverlay} //"loadingOverLay"
          loadingOverlayComponentParams={{
            loadingMessage: 'Loading...'
          }}
          defaultColDef={defaultColumnDefs}
          pagination={true}
          // frameworkComponents={{
          //   //currencyFormatter: CurrencyFormatter
          //   //customHeaderGroup: BasicGroupingHeader,
          //   //loadingOverLay: LoadingOverlay,
          //   //AGGridBooleanFilter: AGGridBooleanFilter,
          //   //AGGridCheckmarkRenderer: AGGridCheckmarkRenderer
          // }}
          onGridReady={(event) => { 
            console.log('onGridReady')
            event.api.sizeColumnsToFit()
            event.columnApi.applyColumnState({
              state: [
                {
                  colId: 'createdOn',
                  sort: 'desc'
                }
              ]
            })
          }}
          onSelectionChanged={(event) => {
            setGridSelection()
          }}
          // getRowStyle={function (params) {
          //   if (!params.data.isValid) {
          //   //   return {
          //   //     'background-color': 'inherit'
          //   //   }
          //   // }
          //   // else {
          //     return {
          //       'background-color': 'var(--bs-yellow)'
          //     }
          //   }
          // }}
        >
        </AgGridReact>
      </div>
    </Fragment>
  )
}

export default DashboardGrid
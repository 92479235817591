import { Counter } from "./components/Counter";
import Dashboard from "./components/Dashboard/Dashboard";
import { FetchData } from "./components/FetchData";
//import Upload from "./components/Flow/Upload";
//import { Home } from "./components/Home";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";

export const AppRoutes = [
  // {
  //   index: true,
  //   element: <Home />
  // },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    requireAuth: true,
    element: <FetchData />
    },
  ...ApiAuthorizationRoutes
];

export const FullLayoutAppRoutes = [
  {
    path: '/dashboard',
    requireAuth: true,
    element: <Dashboard />
  },
];
import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Login } from './Login'
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { UserSession } from './UserSession';

//const ApiAuthorizationRoutes = () => {

//    const loginAction = (name) => {
//        return (<Login action={name}></Login>);
//    }

//    const logoutAction = (name) => {
//        return (<Logout action={name}></Logout>);
//    }

//    return(
//        <Fragment>
//            <Route path="/user-session" component={UserSession} />
//            <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
//            {/*<Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />*/}
//            {/*<AuthorizeRoute path={ApplicationPaths.LoginMustChangePassword} component={() => loginAction(LoginActions.LoginMustChangePassword)} role={Roles.User} />*/}
//            {/*<Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />*/}
//            {/*<Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />*/}
//            {/*<AuthorizeRoute path={ApplicationPaths.ChangePassword} component={() => loginAction(LoginActions.ChangePassword)} role={Roles.User} />*/}
//            {/*<AuthorizeRoute path={ApplicationPaths.Register} component={() => loginAction(LoginActions.Register)} role={Roles.Administrator} />*/}
//            <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
//            {/*<Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />*/}
//            {/*<Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />*/}
//        </Fragment>
//    );
//}

const ApiAuthorizationRoutes = [
    {
        path: '/user-session',
        requireAuth: true,
        element: <UserSession />
    },
];

export const loginAction = (name) => {
    return (<Login action={name}></Login>);
}

export const logoutAction = (name) => {
    return (<Logout action={name}></Logout>);
}

export default ApiAuthorizationRoutes
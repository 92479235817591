import React from 'react'
import { QueryParameterNames, LogoutActions } from './ApiAuthorizationConstants';
import { useAuth } from '../../context/AuthContext';

const Logout = ({ action }) => {
    const { isAuthenticated, logoutUrl } = useAuth()

    console.log('logoutUrl', logoutUrl)

    const getReturnUrl = (state) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    const redirectToApiAuthorizationPath = (apiAuthorizationPath) => {
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    const logout = (returnUrl) => {
        
        if (isAuthenticated) {
            redirectToApiAuthorizationPath(logoutUrl)
        } else {
            alert("You successfully logged out!");
        } /*else {
            this.setState({ message: "You successfully logged out!" });
        }*/
    }

    switch (action) {
        case LogoutActions.Logout:
            if (!!window.history.state.usr.local) {
                logout(getReturnUrl());
            } else {
                // This prevents regular links to <app>/authentication/logout from triggering a logout
                this.setState({ isReady: true, message: "The logout was not initiated from within the page." });
            }
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
    }

    return (
        <div>Processing logout</div>
    )
}

export { Logout }
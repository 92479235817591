import { useNavigate } from "react-router-dom";
import { QueryParameterNames, ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import { toast } from 'react-toastify';
import { useAuth } from '../context/AuthContext';

export { useFetchWrapper };

function useFetchWrapper() {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth()

  const request = (method) => {
    return async (url, body) => {
      const requestOptions = {
          method,
          headers: authHeader()
      };
      requestOptions.headers['redirect'] = 'follow';
      if (body) {
          requestOptions.headers['Content-Type'] = 'application/json';
          requestOptions.body = JSON.stringify(body);
      }

      return fetch(url, requestOptions).then((response) => handleResponse(response));
    }
  }

  const requestBlob = (method) => {
    return async (url, body) => {
      const requestOptions = {
          method,
          headers: authHeader()
      };

      if (body) {
          requestOptions.headers['Content-Type'] = 'application/json';
          requestOptions.body = JSON.stringify(body);
      }

      return fetch(url, requestOptions).then((response) => handleBlobResponse(response));
    }
  }

  // helper functions
  function authHeader() {
    if (isAuthenticated) {
      return { 'X-CSRF': 1 };
    } else {
      return {};
    }
  }
    
  function handleResponse(response) {
    return new Promise((resolve, reject) => { 
      return response.text().then(text => {
    
        let data = undefined
        try
        {
            data = text && JSON.parse(text);
        }
        catch
        {
            data = { message: "Unexpected response: " + response.status }
        }

        if (!response.ok) {
          if ([401, 403].includes(response.status) && isAuthenticated) {
            const returnUrl = window.location.href;
            const loginReturnUrl = `${window.location.origin}/${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`

            console.log(returnUrl)
            console.log(loginReturnUrl)

            //const returnUrl = `?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
            // const logoutPath = {
            //   pathname: `${ApplicationPaths.LogOut}`,
            //   search: `?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(loginReturnUrl)}`,
            //   state: { local: true }
            // };
            navigate(ApplicationPaths.LogOut, {state: { local: true }})

            data = { message: `Login Necessary (${response.status})` }
          }
          else if (response.redirected) {
            data = { message: `Redirecting (${response.status})` }
            window.location.href = response.url;
          }

          const error = (data && data.message) || (data && data.title) || response.statusText;
          toast.error(error, {
            toastId: 'fetch-wrapper-toast-id',
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
          })

          return reject(error);
        }

        return resolve(data);
      })
    })
  }

  function handleBlobResponse(response, authToken) {
    return new Promise((resolve, reject) => { 
      let data = undefined
      if(response.status == 200) {
        return response.blob().then(blob => {
          let data = undefined
          try
          {
            data = blob;
          }
          catch
          {
            data = { message: "Unexpected response: " + response.status }
          }

          return resolve(data);
        })
      } else if (!response.ok) {
        if ([401, 403].includes(response.status) && authToken) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          const returnUrl = window.location.href;
          const loginReturnUrl = `${window.location.origin}/${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`

          console.log(returnUrl)
          console.log(loginReturnUrl)

          //const returnUrl = `?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`
          // const logoutPath = {
          //     pathname: `${ApplicationPaths.LogOut}`,
          //     search: `?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(loginReturnUrl)}`,
          //     state: { local: true }
          // };
          // history.push(logoutPath)
          navigate(ApplicationPaths.LogOut, {state: { local: true }})

          data = { message: `Login Necessary (${response.status})` }
        
        } else if (response.redirected) {
          data = { message: `Redirecting (${response.status})` }
          window.location.href = response.url;
        }

        const error = (data && data.message) || (data && data.title) || response.statusText;
        toast.error(error, {
          toastId: 'fetch-wrapper-toast-id',
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        })
            
        return reject(error);
      }

      return resolve(data);
    })
  }


  const fetchDropDown = async (serviceUrl, valueName, labelName) => {
    const get = request('GET');
    const data = await get(serviceUrl)

    var mapped = data.map(x => ({ value: x[valueName], label: x[labelName] }))

    return mapped
  }

  const fetchBlob = async (serviceUrl) => {
    const get = requestBlob('GET');
    const data = await get(serviceUrl)

    return data
  }

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    fetchDropDown,
    fetchBlob
  }
}
import { useFetchWrapper } from "./fetch-wrapper";

const usePopDocService = () => {

  const fetchWrapper = useFetchWrapper()

  const PostRequest = async () => {
    return fetchWrapper.post(`popdoc`)
  }

  const SubmitRequest = async (files) => {
    return fetchWrapper.post('popdoc/files', files)
  }

  return { PostRequest, SubmitRequest }
}

export { usePopDocService };
import React, { Fragment } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import {  PersonCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { ApplicationPaths } from './ApiAuthorizationConstants';

const LoginMenu = (props) => {
    const { isAuthenticated, userName /*, loginUrl, logoutUrl*/ } = useAuth()

    const authenticatedView = (authName, /*profilePath,*/ logoutPath/*, logoutState*/) => {
        const logoutState = { local: true };

        console.log('logoutPath', logoutPath)
        console.log('authName', authName)

        const renderMenuItem = (path, text, color, isLast = false) => {
            return (
                <DropdownItem className="m-0 p-0 profile-menu-item">
                    <NavLink tag={Link} className="text-dark m-0 p-0" to={path}>
                        <div className={`d-flex flex-row align-items-center border border-${color} ${isLast ? 'border-top-0 rounded-bottom' : ''}`}>
                            <div className={`flex-shrink p-1 bg-${color}`}>&nbsp;</div>
                            <div className="flex-grow ps-2">{text}</div>
                        </div>
                    </NavLink>
                </DropdownItem>
            )
        }

        return (<Fragment>
            {/*<NavItem>*/}
            {/*    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>*/}
            {/*</NavItem>*/}
            {/* Hello {authName}
            <NavItem>
                <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>Logout</NavLink>
            </NavItem> */}
            <UncontrolledDropdown>
                <DropdownToggle
                    data-toggle="dropdown"
                    className="btn btn-primary btn-circle"
                    tag="button"
                    caret
                >
                    <PersonCircle />
                </DropdownToggle>
                <DropdownMenu end className="pb-0 pt-0 border-0">
                    <DropdownItem header className="text-white p-3 bg-primary border border-primary border-bottom-0 rounded-top">
                        <div className="d-flex flex-fill flex-column align-items-center" style={{minWidth: '250px'}}>
                            <PersonCircle size={40} />
                            <div className="mt-2">{authName}</div>
                        </div>
                    </DropdownItem>
                    {/* {renderMenuItem('/admin/settings', 'User Settings', 'light')} */}
                    {/* {renderMenuItem(profilePath, 'Change Password', 'light', true)} */}
                    {renderMenuItem(logoutPath, 'Logout', 'light', true)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment>);
    }

    const anonymousView = (loginPath) => {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }

    console.log('isAuthenticated', isAuthenticated)
    //console.log('loginUrl', loginUrl)
    //console.log('logoutUrl', logoutUrl)

    return (
        <Fragment>
            { isAuthenticated ? (
                //authenticatedView('Jojo', logoutUrl)
                authenticatedView(userName, ApplicationPaths.LogOut)
            ) : (
                anonymousView(ApplicationPaths.Login)
                //anonymousView(loginUrl)
   
            )}
        </Fragment>
    )
}

export default LoginMenu
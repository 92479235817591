import React from 'react'
import { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
//import authService from './AuthorizeService'

const AuthorizeRoute = ({ element, path }) => {
    const { isAuthenticated, isLoading } = useAuth()

    const elementOrLogin = () => {
        //const redirectUrl = `${ApplicationPaths.Login}`
        const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
        const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

        if (isAuthenticated) {
            return (element)
        }
        else {
            return <Navigate replace to={redirectUrl} />
        }
    }

    console.log('isAuthenticated', isAuthenticated)
    console.log('isLoading', isLoading)

    var link = document.createElement("a");
    link.href = path;
    //const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    //const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;
    //const redirectUrl = `/bff/login?returnUrl=${encodeURIComponent(returnUrl)}`;
    

    return isLoading ? (<div></div>) : elementOrLogin()

    //return element
//    return (
//        <Fragment>
//            isLoading ? (
//                <div></div>;
//            ) : (
//                isAuthenticated ? element : <Navigate replace to={redirectUrl} />;
//            )
//        </Fragment>
//    )
}

export default AuthorizeRoute
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PlainSwal = () => {

  const MySwal = withReactContent(Swal)

  MySwal.mixin({
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
        icon: ''                       // disable icon animation
      },
      hideClass: {
        popup: '',                     // disable popup fade-out animation
      }
    })

    return (MySwal)
}

const WaitSwal = ({message}) => {
  const plainSwal = PlainSwal()

  plainSwal.fire({
    title: message ?? 'Processing...',
    allowEscapeKey: false,
    allowOutsideClick: false,
    timerProgressBar: true,
    didOpen: () => {
      plainSwal.showLoading()
    }
  })

  return (plainSwal)
}

export { PlainSwal, WaitSwal }
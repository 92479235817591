import React from 'react';
import { NumericFormat } from 'react-number-format';


const CurrencyFormatter = (props) => {
  return (
    <NumericFormat value={props.value} defaultValue={0} displayType={'text'} thousandSeparator={true} decimalScale={2} fixedDecimalScale={true}  prefix={'$'} />
  )
}

export default CurrencyFormatter
import React, { Fragment, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { usePopDocService } from '../../services/PopDocService'
import { Button,  FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ArrowClockwise, FileEarmarkPlus } from 'react-bootstrap-icons'
import Select from 'react-select'
import DashboardGrid from './DashboardGrid'
import CustomDateRangePicker from '../widgets/CustomDateRangePicker'
import { customStyles } from '../widgets/Dropdowns/styles'
import { pdfjs, Document, Page } from 'react-pdf';
import { FileUploader } from '../widgets/FileUploader'
import { WaitSwal } from '../widgets/PlainSwal'
import { toast } from 'react-toastify';


const Dashboard = () => {

  const { SubmitRequest } = usePopDocService()
  const canvasRef = useRef(null)
  const documentRef = useRef(null)
  const fileUploaderRef = useRef(null)

  const perPageOptionValues = useMemo(() => [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 150, 200, 300, 400, 500], [])
  const perPageOptions = useMemo(() => perPageOptionValues.map(value => { return { value: value, label: value.toString() }}), [perPageOptionValues])

  const documentOptions = useMemo(() => { return {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
  }}, [])
  
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const sampleListing = [
    {
      createdOn: new Date(),
      vendor: 'Home Depot',
      invoice: '3847',
      billDate: new Date(),
      dueDate: new Date(),
      amount: 345.34,
      property: '45 Berch Rd.',
      stage: 'Pending'
    }
  ]

  const initialState = {
    isLoading: false,
    perPage: { value: 30, label: '30' },
    dateSelectionRange: [{
      startDate: new Date().toLocaleDateString() + ' 00:00',
      endDate: new Date().toLocaleDateString() + ' 00:00',
      key: 'selection',
    }],

    listingData: [],
    listingSelection: [],
    displayOptions: 'hidden',

    documentBlobUrl: null,
    pageNumber: 1,
    numPages: 1,
    scale: 1,
    documentIsRendered: false,
    
    showUploader: false,
    isDocumentUploading: false,
    documents: [],
    hasDocument: false
  }

  const stateReducer = (state, action) => {
    switch (action.type) {
      case 'SET': {
        return {
          ...state,
          [action.name]: action.value
        }
      }
      default:
        return state    
    }

    //return state
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'SET', name: 'isLoading', value: true })
      dispatch({ type: 'SET', name: 'displayOptions', value: 'visible' })
    }, 200)
  
    setTimeout(() => {
      dispatch({ type: 'SET', name: 'listingData', value: sampleListing })
      dispatch({ type: 'SET', name: 'isLoading', value: false })
    }, 2000)
  }, [])

 
  //   setTimeout(() => {
  //     dispatch({ type: 'SET', name: 'isLoading', value: false })
  //   }, 6000)
  // }, [])


  const [state, dispatch] = useReducer(stateReducer, initialState)

  const onPageSizeChanged = useCallback((selected) => {
    dispatch({ type: 'SET', name: 'perPage', value: selected })
  }, [])

  const onDateSelect = useCallback((selected) => {
    console.log('Date Selected: ', selected)

    dispatch({ type: 'SET', name: 'dateSelectionRange', value: selected })
  }, [])

  const onSelectionChanged = useCallback((selectedData) => {
    console.log('selectedData',selectedData)
    dispatch({ type: 'SET', name: 'listingSelection', value: selectedData })
  }, [])

  const toggleUploader = useCallback(() => {
    //dispatch({ type: 'SET', name: 'showEditor', value: !state.showEditor })
    //dispatch({ type: 'RESET_EDITOR' })
    dispatch({ type: 'SET', name: 'showUploader', value: !state.showUploader })
  }, [state.showUploader])

  const cancelUpload = useCallback(async () => {
    
    if (fileUploaderRef && fileUploaderRef.current) {
      await fileUploaderRef.current.flush()
    }
    
    toggleUploader()
  }, [toggleUploader])

  const onRefresh  = (e) => {
    e.currentTarget.blur()
    //onShowLoading()
    //populateState()
    //loadMailings()
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    dispatch({ type: 'SET', name: 'numPages', value: numPages })
  }

  const onRenderSuccess = () => {
    dispatch({ type: 'SET', name: 'documentIsRendered', value: true })
  }

  const onUploadFilesChange = (newItems) => {
    dispatch({ type: 'SET', name: 'documents', value: newItems.fixedFiles })
    dispatch({ type: 'SET', name: 'hasDocument', value: newItems.hasDocument })
    console.log('onChange newItems', newItems)
  }

  const onIsUploadingChanged = useCallback((isUploadingState) => {
    console.log('isUploadingState', isUploadingState)
    dispatch({ type: 'SET', name: 'isDocumentUploading', value: isUploadingState })
  }, [])

  const submitDocuments = useCallback(async () => {

    const swal = WaitSwal({message: 'Saving Documents...'})

    const files = []

    state.documents.forEach(file => {
      console.log("file.serverId", file.serverId)
      let str = file.serverId
      if(str.startsWith("\"") && str.endsWith("\""))
      {
          str = str.substring(1, str.length-1);
      }
      
      files.push(str)
    });

    const params = {
      files
    }

    console.log('waiting')

    await SubmitRequest(params)

    console.log('didn\'t wait')

    setTimeout(() => {
      toggleUploader()

      swal.close()

      toast.success('Documents Submitted', {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }, 200);
  }, [SubmitRequest, state.documents, toggleUploader])

  // const onRenderSuccess = (e) => {
  //   console.log('onRenderSuccess', e)
  //   console.log('onRenderSuccess._pageInfo', e._pageInfo)
  //   console.log('onRenderSuccess._pageInfo.view', e._pageInfo.view)

  //   const canvasRect = canvasRef.current?.getBoundingClientRect()
  //   const page1Base64 = canvasRef.current.toDataURL("image/png");

  //   const [x, y, w, h] = e._pageInfo.view
  //   console.log('w', w)
  //   console.log('h', h)

  //   console.log('width:', canvasRef.current.getAttribute('width'), 'height:', canvasRef.current.getAttribute('height'))

  //   const dynamicScale = canvasRect.width / w
  //   dynamicScaleRef.current = dynamicScale
  //   documentWidthRef.current = w

  //   console.log('dynamicScaleRef.current', dynamicScale)
    
  //   onScaleChange(dynamicScale)
  //   onInitMarkers(markers, dynamicScale)
  //   //onDocumentIsRendered(true, canvasRect.width, canvasRect.height, page1Base64)
  //   onDocumentIsRendered(true, w, h, page1Base64)
  //   //onDocumentIsRendered(true, canvasRef.current.getAttribute('width'), canvasRef.current.getAttribute('height'), page1Base64)
    
  // }


  return (
    <Fragment>
      <div className='d-flex flex-row'>
        <div className='mt-3' style={{flex: '2 1 33%'}}>
          <div className='grid-outer-buttons justify-content-between'>
            <div className="d-flex flex-fill justify-content-start align-items-center">
              <Label className="mb-0 me-3">Date:</Label>
              <CustomDateRangePicker
                onChange={onDateSelect}
              />
            </div>
            <div className="d-flex flex-fill justify-content-end align-items-center">
              <Button color="dark" className="ms-4" onClick={onRefresh}><ArrowClockwise /><span className='ms-1 me-2'>Refresh</span></Button>
            </div>
          </div>
          <div style={{visibility: state.displayOptions, flex: '1 1 0px', width: '100%', overflow: 'hidden'}}>
            <DashboardGrid
              perPage={state.perPage}
              listingData={state.listingData}
              isLoading={state.isLoading}
              onSelectionChanged={onSelectionChanged}
            />
            <div className='grid-outer-buttons bottom justify-content-between mb-3'>
              <div className="d-flex flex-fill justify-content-start align-items-center">
                <Button color='dark' onClick={toggleUploader}><div className='d-flex flex-row align-items-center'><div className='d-flex me-2 pt-1'><FileEarmarkPlus /></div><div className='d-flex pe-2'>Upload</div></div></Button>
              </div>
              <div className="d-flex flex-fill justify-content-end align-items-center">
                <Label className="mb-0 ms-4 me-3">Results/page:</Label>
                <Select
                  menuPlacement='top'
                    styles={customStyles}
                    options={perPageOptions}
                    value={state.perPage}
                    onChange={ onPageSizeChanged }
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{flex: '1 1 33%'}}>
          <div className='d-flex flex-column pe-3 ps-3' style={{overflowY: 'auto', position: 'relative'}}>
            <FormGroup tag="fieldset" className="border p-2 mt-1">
              <legend className="float-none w-auto ps-1 pe-1 fs-6 text-muted" style={{fontWeight: '500'}}>Document Viewer</legend>

              <div className="Example__container">
                <div className="Example__container__document">
                  {state.hasDocument && (
                  <Document
                    file={state.documentBlobUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={documentOptions}
                    inputRef={documentRef}
                  >
                    <Page pageNumber={state.pageNumber} scale={state.scale} onRenderSuccess={onRenderSuccess} canvasRef={canvasRef}/>

                  </Document>
                  )}
                </div>
                {state.documentIsRendered && (
                <p className='pt-4'>
                  Page {state.pageNumber} of {state.numPages}
                </p>
                )}
              </div>
            </FormGroup>
          </div>
        </div>
      </div>

      <Modal isOpen={state.showUploader} keyboard={false} toggle={cancelUpload} size='xl'>
          <ModalHeader toggle={cancelUpload} className='text-primary bg-light'><FileEarmarkPlus size={28} /> <span className='text-white'>Upload Documents</span></ModalHeader>
          <ModalBody className='' style={{maxHeight: '75vh', overflowY: 'scroll'}}>
            <FileUploader
              ref={fileUploaderRef}
              labelIdle='Drag & Drop your Documments (PDFs and Images) or <span class="filepond--label-action">Browse</span>'
              labelFileProcessing='Loading Document'
              labelFileProcessingComplete='Ready'
              //onRemove={onPdfRemove}
              //onRemoved={onPdfRemoved}
              onRemoved={(files) => {
                console.log('onRemoved part static file', files)
                onUploadFilesChange({
                  //documentBlobUrl: '', 
                  fixedFiles: files,
                  hasDocument: (files && files.length > 0)
                })
              }}
              onBlobUrlReady={(url, files) => { 
                console.log('onBlobUrlReady')
                onUploadFilesChange({
                  //documentBlobUrl: url, 
                  fixedFiles: files,
                  hasDocument: (files && files.length > 0)
                })
              }}
              //onChange={(files) => dispatch({ type: 'SET', name: 'files', value: { files: files }})}
              onChange={(files) => {
                console.log('onChange')
                // dispatch({ type: 'SET', value: {
                //   fixedFiles: files
                // }});
              }}
              onIsUploadingChanged={onIsUploadingChanged}
            />
          </ModalBody>
          <ModalFooter>
              <Button color="primary" onClick={submitDocuments} disabled={state.isDocumentUploading || !state.hasDocument}>
                Submit
              </Button>{' '}
              <Button color="secondary" onClick={cancelUpload}>
                Cancel
              </Button>
            </ModalFooter>
      </Modal>
    </Fragment>
  )
}

export default Dashboard
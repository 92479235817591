import React, { useState, useEffect, useContext } from "react";

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);
export const AuthProvider = ({
    children
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [userName, setUserName] = useState();
    const [loginUrl, setLoginUrl] = useState();
    const [logoutUrl, setLogoutUrl] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getUser = async () => {
        /*const response = await fetch('/bff/user');
        const json = await response.json();

        setIsAuthenticated(json.isAuthenticated);
        setIsLoading(false);
        if (json.isAuthenticated) setUser(json.claims);*/


        try {
            var req = new Request("/bff/user", {
                headers: new Headers({
                    'X-CSRF': '1'
                })
            })

            var resp = await fetch(req);
            console.log(resp)
            if (resp.ok && resp.status === 200) {
                
                const data = await resp.json();
                setUser(data)

                const logoutUrlClaimValue =
                    data.find((claim) => claim.type === "bff:logout_url")?.value ??
                    logoutUrl;

                const name = data.find((claim) => claim.type === "name")?.value ?? ""

                setLogoutUrl(logoutUrlClaimValue)
                setLoginUrl('/bff/login')
                setIsAuthenticated(true)
                setUserName(name)
                setIsLoading(false)
            } else {
                console.log('bad response!!')
                setIsAuthenticated(false)
                setLoginUrl('/bff/login')
                setIsLoading(false)
            }
        } catch (e) {
            console.error(e);
            //this.setState({ loggedIn: false });
            setIsAuthenticated(false)
            setLoginUrl('/bff/login')
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getUser();
    }, []);

    //const login = () => {
        //window.location.href = '/bff/login';
    //}

    //const logout = () => {
        //window.location.href = logoutUrl; //'/bff/logout';
    //}

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                user,
                userName,
                isLoading,
                loginUrl,
                logoutUrl,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
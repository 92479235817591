import React from 'react'

const Footer = () => {
  const year = new Date().getFullYear()
  
  return (
    <footer>
          &copy; {year} Efformance
    </footer>
  )
}

export default Footer
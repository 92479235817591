import React, { forwardRef, useImperativeHandle, useRef } from 'react'
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import "filepond/dist/filepond.min.css";

export const FileUploader = forwardRef(({/*files,*/ onChange, onIsUploadingChanged, onRemove, onRemoved, onBlobUrlReady, labelIdle, labelFileProcessing, labelFileProcessingComplete }, ref) => {
  const filePondRef = useRef(null)

  useImperativeHandle(ref, () => {
    return {
      async flush() {
        console.log('flush called')

        if (filePondRef && filePondRef.current) {
          console.log('removeFiles')
          await filePondRef.current.removeFiles({ revert: true })
          onRemoved([]);
        }
      }
    }
  }, [filePondRef, onRemoved])

  registerPlugin(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginPdfPreview,
    FilePondPluginFileValidateSize
  );  

  const isLoadingCheck = () => {
    if (filePondRef && filePondRef.current) {
      const files = filePondRef.current.getFiles()
      var isLoading = files.filter(x=>x.status !== 5).length !== 0;
      onIsUploadingChanged(isLoading)
    }
  }

  return (
    <FilePond
      ref={filePondRef}
      className='mb-1'
      allowFileSizeValidation={true}
      maxFileSize="29MB"
      pdfPreviewHeight="426"
      imagePreviewHeight="426"
      pdfComponentExtraParams='navpanes=0&toolbar=1&view=fitW&page=1'
      allowMultiple={true}
      //files={files}
      acceptedFileTypes={['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'image/bmp']}
      fileValidateTypeLabelExpectedTypesMap={{'application/pdf': '.pdf', 'image/jpeg': '.jpg', 'image/png': '.png', 'image/tiff': '.tiff', 'image/bmp': '.bmp'}}
      server={{
        url: '/attachment',
        //timeout: 7000,
        process: {
          headers: {
            "X-CSRF": 1
          }
        },
        revert: {
          headers: {
            "X-CSRF": 1
          }
        },
        fetch: null,
        load: null,
        restore: null
      }}
      labelFileProcessing={labelFileProcessing}
      labelFileProcessingComplete={labelFileProcessingComplete}
      beforeRemoveFile={(file) => {
        console.log(file)
        if (onRemove) {
          onRemove(file)
        }
      }}
      onremovefile={(error, removedFile) => {
        console.log(error)
        console.log('onremovefile do loading check')
        if (onRemoved && filePondRef && filePondRef.current) {
          console.log('onRemoved', removedFile.id)
          const currentFiles = filePondRef.current.getFiles()
          console.log('onRemoved currentFiles', currentFiles)
          const removedFileIndex = currentFiles.findIndex(file => file.id === removedFile.id)
          if (removedFileIndex !== -1) {
            console.log('onRemoved index', removedFileIndex)
            const newFiles = [
              ...currentFiles.slice(0, removedFileIndex),
              ...currentFiles.slice(removedFileIndex + 1)
            ]
            onRemoved(newFiles);
          } else {
            onRemoved(currentFiles);
          }
        }
        
        isLoadingCheck()
      }}
      onload={() => {
        console.log('on load called')
      }}
      oninitfile={async (file) => {
        // console.log('Init File', file);
      }}
      // onLoad={(file) => {
      //   console.log('On Load')
      //   if (files && files.length !== 0) {
      //     onBlobUrlReady(null, [])
      //   }
      // }}
      onaddfile={() => console.log('Add File')}
      onprocessfile={async (error, file)  => {
        if (file) {
          console.log(file)
          const url = URL.createObjectURL(file.source)
          console.log(url)
          onBlobUrlReady(url, filePondRef.current.getFiles())
        }
      }}
      onupdatefiles={newFiles => {
        //onChange(newFiles)
      }}
      onaddfilestart={(file) => {
        console.log('onaddfilestart do loading check')
        isLoadingCheck()
      }}
      onprocessfiles={() => {
        console.log('onprocessfiles do loading check')
        isLoadingCheck()
      }}
      onprocessfileabort={() => {
        console.log('onprocessfileabort do loading check')
        isLoadingCheck()
      }}
      onprocessfilerevert={() => {
        console.log('onprocessfilerevert do loading check')
        isLoadingCheck()
      }}
      labelIdle={labelIdle}
    />
  )
})
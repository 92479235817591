import React from 'react'
import { LoginActions, QueryParameterNames } from './ApiAuthorizationConstants';
import { useAuth } from '../../context/AuthContext';


const Login = ({ action }) => {
    const { loginUrl } = useAuth()

    console.log('loginUrl', loginUrl)

    //useEffect(() => {

    //}, [action, login, getReturnUrl])

    const getReturnUrl = (state) => {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    const redirectToApiAuthorizationPath = (apiAuthorizationPath) => {
        console.log('up in here?')
        console.log(window.location.origin)
        //const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
        const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    const login = (returnUrl) => {
        redirectToApiAuthorizationPath(loginUrl)
    }

    switch (action) {
        case LoginActions.Login:
            login(getReturnUrl());
            break;
        default:
            throw new Error(`Invalid action '${action}'`);
    }

    return (
        <div>Processing login</div>
    )
}

export { Login }
import React, { Fragment }  from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AppRoutes, FullLayoutAppRoutes } from './AppRoutes';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import { Layout } from './components/Layout';
import { ApplicationPaths, LoginActions, LogoutActions } from './components/api-authorization/ApiAuthorizationConstants';
import { Login } from './components/api-authorization/Login';
import { Logout } from './components/api-authorization/Logout';
import { useAuth } from './context/AuthContext';
import { LoadingOverlay } from './components/widgets/LoadingOverlay';
import './custom.scss'
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";
import { LayoutFull } from './components/LayoutFull';

const App = () => {

  const { isLoading } = useAuth()

  return (
    <Fragment>
      <Routes>
          <Route exact path='/' element={
              isLoading ? <LoadingOverlay /> : <Navigate to="/dashboard" replace  />
            }
          />
          <Route path={ApplicationPaths.Login} element={<Login action={LoginActions.Login} />} />
          <Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout} />} />
        <Route element={<Layout />}>
          {AppRoutes.map((route, index) => {
            const { element, requireAuth, ...rest } = route;
            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
          })}
        </Route>
        <Route element={<LayoutFull />}>
          {FullLayoutAppRoutes.map((route, index) => {
            const { element, requireAuth, ...rest } = route;
            return <Route key={index} {...rest} element={requireAuth ? <AuthorizeRoute {...rest} element={element} /> : element} />;
          })}
        </Route>
      </Routes>
    <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
    </Fragment>
  );
}

export default App